.first-component {
  padding: 58px 10vw 0;
}

.second-component {
  padding: 0 17vw !important;
}

.horizontal-flex-1 {
  width: 50%;
}

.phone_horizontal {
  width: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reverse {
  flex-direction: column-reverse;
}

.left-top {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-top {
  padding-top: 180px;
}

.left-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

#right-bottom-img {
  max-height: 420px;
  max-width: 446px;
}

.third-component {
  padding: 100px 10vw !important;
}

.fourth-component {
  padding: 100px 35vw !important;
}

.outer-container {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
}

.inner-container {
  height: 201px;
  width: 201px;
  /* background-color: #cfd6e8; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inner-container p {
  margin-top: 25px;
}

.pointerCursor {
  cursor: pointer;
}

.rounded-image {
  border-radius: 50%;
  background-color: var(--base-white);
  cursor: pointer;
  padding: 4px;
}

.icon-contact {
  width: 40%;
  justify-content: space-around;
}

.top-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-btn {
  position: relative;
  bottom: 65px;
  height: 0;
  display: flex;
  justify-content: center;
}

.q-field__native {
  font-size: 20px;
  margin: 10px 0;
}

.fourth-component .q-field__label {
  font-size: 16px !important;
}

.fourth-component .q-field__native {
  font-size: 16px !important;
}

.top_cont {
  width: 100%;
}

/* text style */
.text-45 {
  font-size: 40px;
}

.text-30 {
  font-size: 30px;
}

.text-25 {
  font-size: 23px;
}

.text-24 {
  font-size: 22px;
}

.text-20 {
  font-size: 20px;
}

.text-18 {
  font-size: 17px;
}

.text-16 {
  font-size: 16px;
}

.text-14 {
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.light-font {
  font-family: Helvetica-light;
}

.italic-font {
  font-style: italic;
}

.bold-weight-font {
  font-weight: bold;
}

.white-font {
  color: var(--base-white);
}

.black-font {
  color: var(--main-text-title) !important;
}

.primary-font {
  color: var(--main-text-title);
}

.spacing-15 {
  letter-spacing: 0.15em;
}

.text-align-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* end text style */

/* for normal laptop screen size */
@media screen and (max-width: 1405px) {
  .inner-container {
    height: 181px;
    width: 181px;
  }

  .third-component {
    padding: 88px 10vw 0 !important;
  }
}

/* for small laptop screen size */
@media screen and (max-width: 1024px) {
  .first-component {
    padding: 50px 10vw 0 !important;
  }

  .second-component {
    padding: 0 10vw !important;
  }

  .third-component {
    padding: 88px 7vw 0 !important;
  }

  .fourth-component {
    padding: 88px 10vw 0 !important;
  }

  .inner-container {
    margin-bottom: 114px;
  }
}

/* for tablet screen size */
@media screen and (max-width: 768px) {
  .inner-container {
    height: 231px;
    width: 231px;
    margin-right: 10px;
  }
}

/* for phone screen size */
@media screen and (max-width: 612px) {
  .inner-container {
    width: 40vw;
    height: 40vw;
    margin-right: 0;
  }
}
